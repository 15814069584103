<template>
  <section class="account-details-page">
    <!-- Sidebar Section -->
    <aside class="sidebar">
      <h2>Hello, Meimei!</h2>
      <ul class="sidebar-menu">
        <li
          :class="{ active: $route.name === 'AccountInfo' }"
          @click="changeSection('account-info')"
        >
          Account Details
        </li>
        <li
          :class="{ active: $route.name === 'Purchases' }"
          @click="changeSection('purchases')"
        >
          Purchases
        </li>
        <li
          :class="{ active: $route.name === 'Warehouse' }"
          @click="changeSection('warehouse')"
        >
          Warehouse
        </li>
        <li
          :class="{ active: $route.name === 'Shipments' }"
          @click="changeSection('shipments')"
        >
          Shipments
        </li>
        <li
          :class="{ active: $route.name === 'AddressBook' }"
          @click="changeSection('address-book')"
        >
          Address Book
        </li>
      </ul>
    </aside>

    <!-- Dynamic Content Section -->
    <div class="details-container">
      <router-view />
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    changeSection(section) {
      const targetRoute = `/account-details/${section.toLowerCase()}`;

      if (this.$route.path !== targetRoute) {
        this.$router.push(targetRoute).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err; // If it's a different error, rethrow it
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.account-details-page {
  display: flex;
  padding-left: 70px;
}

.sidebar {
  width: 20%;
  background-color: white;
}

h2 {
  font-family: "Chunk Five", serif;
  font-size: 24px;
  margin-top: 70px;
  margin-bottom: 40px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  padding: 20px 15px;
  cursor: pointer;
  font-size: 16px;
  width: 50%;
}

.sidebar-menu li.active {
  background-color: #ffde59;
  font-weight: bold;
  border-left: 5px solid black;
}

.details-container {
  width: 75%;
  margin-top: 30px;
  margin-left: 15px;
}
</style>
