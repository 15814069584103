<template>
    <footer class="footer">
      <div class="footer-brand">Borderless.</div>
      <div class="footer-right">
        <span>1 CAD ≈ 0.74 USD + 5% Fees</span>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'Footer'
  };
  </script>
  
  <style scoped>
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: black;
    color: #ffde59;
  }
  
  .footer-right {
    display: flex;
    align-items: center;
  }
  </style>
  