<template>
    <div class="purchases">
      <h3>Purchases</h3>
  
      <!-- Order List -->
      <div
        class="order-card"
        v-for="order in orders"
        :key="order.orderNo"
      >
        <div class="order-info">
          <p><strong>Order No.</strong></p>
          <p>{{ order.orderNo }}</p>
          <p>{{ order.date }}</p>
          <p style="margin-top: 10px">
            <strong>Total ${{ order.total }}</strong>
          </p>
        </div>
        <div class="order-status" :class="order.statusClass">
          {{ order.status }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Purchases",
    data() {
      return {
        orders: [
          {
            orderNo: "123456789",
            date: "09/10/2024",
            total: 120,
            status: "Ready to ship",
            statusClass: "ready-to-ship",
          },
          {
            orderNo: "123456780",
            date: "09/10/2024",
            total: 120,
            status: "Confirmed",
            statusClass: "confirmed",
          },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .purchases {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  h3 {
    font-family: "Chunk Five", serif;
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  .order-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 30px;
    width: 80%;
    margin-bottom: 30px;
    border-radius: 10px; /* Rounded corners */
    border: 2px solid #efefef; /* Change this color to your desired border color */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative; /* Needed for absolute positioning of the status */
  }
  
  .order-info {
    font-family: "Canva Sans", sans-serif;
    font-size: 16px;
  }
  
  .order-info p {
    margin: 5px 0;
  }
  
  .order-status {
    position: absolute; /* To position the status in the top-right */
    top: 20px;
    right: 30px;
    font-family: "Canva Sans", sans-serif;
    font-weight: bold;
    font-size: 16px;
  }
  
  .ready-to-ship {
    color: green;
  }
  
  .confirmed {
    color: orange;
  }
  </style>
  