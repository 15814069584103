import { render, staticRenderFns } from "./Purchases.vue?vue&type=template&id=573ac0ff&scoped=true"
import script from "./Purchases.vue?vue&type=script&lang=js"
export * from "./Purchases.vue?vue&type=script&lang=js"
import style0 from "./Purchases.vue?vue&type=style&index=0&id=573ac0ff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573ac0ff",
  null
  
)

export default component.exports