<template>
  <section class="brand-list-page">
    <!-- Top-left Title -->
    <div class="title-section">
      <h2>Choose your brand... <span>请选择品牌</span></h2>
    </div>

    <!-- Brand List -->
    <div class="brands-container">
      <div
        v-for="brand in brands"
        :key="brand.id"
        :class="['brand-card', { selected: selectedBrand === brand.id }]"
        @click="selectBrand(brand.id)"
      >
        <div class="brand-content">
          <img :src="brand.image" :alt="brand.name" />
          <div class="button-wrapper">
            <button class="visit-button" @click.stop="visitWebsite(brand.website)">前往官网</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Bar -->
    <div class="search-section">
      <div class="search-container">
        <input
          type="text"
          placeholder="Insert your link here..."
          class="search-input"
        />
        <button class="search-button" @click="searchProduct">Search</button>
      </div>
      <p class="search-hint">*请输入所选品牌加拿大官网上的产品链接</p>
    </div>

    <!-- Product Details Modal -->
    <!-- Element UI Dialog for Product Popup -->
    <el-dialog
      :visible.sync="isModalOpen"
      width="60%"
      :close-on-click-modal="false"
      custom-class="product-dialog"
      @close="closeModal"
      center
    >
      <template v-if="product">
        <div class="modal-content">
          <!-- Left section for the product image -->
          <div class="product-image">
            <img :src="product.image" alt="Product Image" />
          </div>

          <!-- Right section for the product info -->
          <div class="product-info">
            <h3>{{ product.brand_name }}</h3>
            <h2>{{ product.product_name }}</h2>
            <p class="price">$30</p>

            <!-- Loop through grouped variants -->
            <div
              v-for="(variants, variantType) in groupedVariants"
              :key="variantType"
              class="variant-section"
            >
              <h4>{{ variantType }}</h4>
              <div class="variant-options">
                <span
                  v-for="variant in variants"
                  :key="variant.variant_value"
                  :class="[
                    isColorVariant(variant)
                      ? 'variant-circle'
                      : 'variant-square',
                    selectedVariants[variantType] === variant.variant_value
                      ? 'selected'
                      : '',
                  ]"
                  :style="
                    isColorVariant(variant)
                      ? { backgroundColor: variant.variant_value.toLowerCase() }
                      : {}
                  "
                  @click="selectVariant(variantType, variant.variant_value)"
                >
                  <template v-if="!isColorVariant(variant)">
                    {{ variant.variant_value }}
                  </template>
                </span>
              </div>
            </div>

            <div class="actions">
              <button class="add-to-cart-button" @click="addToCart">
                Add to Cart
              </button>
              <button class="more-links-button" @click="addMore">Add More Links</button>
            </div>
          </div>
        </div>
      </template>
    </el-dialog>

    <!-- TODO -->
    <div class="test-section">
      <div class="search-container">
        <router-link to="/cart">
          <button class="test-button">Shopping Cart</button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "BrandList",
  data() {
    return {
      selectedBrand: null, // Track the selected brand
      brands: [], // Dynamic list of brands
      product: null, // Store fetched product data
      isModalOpen: false,
      selectedVariants: {}, // Store selected variant values
    };
  },
  computed: {
    groupedVariants() {
      if (!this.product) return {};
      return this.product.variants.reduce((acc, variant) => {
        if (!acc[variant.variant_type]) acc[variant.variant_type] = [];
        acc[variant.variant_type].push(variant);
        return acc;
      }, {});
    },
  },
  methods: {
    async fetchBrands() {
      const loading = this.$loading({
          lock: true,
          text: '正在加载品牌列表',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      try {
        const response = await axios.get(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/brands/list"
        );
        this.brands = response.data; // Populate the brands data
        loading.close();
      } catch (error) {
        console.error("Error fetching brands:", error);
        alert("Failed to load brands. Please try again later.");
      }
    },
    selectBrand(brandId) {
      this.selectedBrand = brandId; // Set the selected brand ID
    },
    async searchProduct() {
      // Check login status
      if (!this.$store.getters.isLoggedIn) {
        alert("You need to login to search for products.");
        this.$router.replace("/login");
        return;
      }

      try {
        const response = await axios.get(
          `https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/products/1`
        );
        console.log(response.data);
        this.product = response.data;
        this.isModalOpen = true;
      } catch (error) {
        console.error("Error fetching product:", error);
        alert("Product not found. Please check the product ID.");
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.product = null;
      this.selectedVariants = {}; // Reset selected variants
    },
    visitWebsite(website) {
      window.open(website, "_blank"); // Open the website in a new tab
    },
    selectVariant(variantType, variantValue) {
      // Store the selected variant by type
      this.$set(this.selectedVariants, variantType, variantValue);
    },
    isColorVariant(variant) {
      return variant.variant_type === "Color";
    },
    async addToCart() {
      try {
        const payload = {
          brand_name: this.product.brand_name,
          product_name: this.product.product_name,
          image: this.product.image,
          quantity: 2, // Hardcoded for demonstration
          variants: Object.entries(this.selectedVariants).map(
            ([variant_type, variant_value]) => ({
              variant_type,
              variant_value,
            })
          ),
        };

        const response = await axios.post(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/cart/create",
          payload,
          {
            params: { user_id: "9" },
            headers: { "Content-Type": "application/json" },
          }
        );

        alert("Added to cart successfully!");
        this.isModalOpen = false;
      } catch (error) {
        console.error("Error adding to cart:", error);
        alert("Failed to add product to cart. Please try again.");
      }
    },
    addMore(){
      this.isModalOpen = false;
    }
  },
  mounted() {
    this.fetchBrands(); // Fetch brands on page load
  },
};
</script>

<style scoped>
.brand-list-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-left: 77px;
  background-color: white;
}

.title-section {
  align-self: flex-start;
  margin-bottom: 10px; /* Adjust margin to reduce space below the title */
}

.title-section h2 {
  font-family: "Chunk Five", serif;
  font-size: 18px;
}

.title-section span {
  font-family: "Canva Sans", sans-serif;
  font-size: 12px;
  margin-left: 10px;
}

.brands-container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
  gap: 30px;
}

.brand-card {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #f0f0f0;
  border-radius: 10px;
  transition: transform 0.3s ease, border 0.3s ease; /* Smooth transition for scaling and border */
  cursor: pointer;
  border: 4px solid transparent; /* Default border transparent */
}

.brand-card.selected {
  border: 4px solid #ffde59; /* Selected brand border */
  transform: scale(1.2); /* Enlarge selected brand */
}

.brand-card:hover {
  transform: scale(1.15); /* Hover effect */
}

.brand-card img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.button-wrapper {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Ensure the button wrapper spans the width */
  display: flex;
  justify-content: center;
}

.brand-card .visit-button {
  background-color: #ff6b3b;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap; /* Prevent text from wrapping into two lines */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Search Section */
.search-section {
  width: 100%;
  height: 100px;
  display: flex;
  margin-top: 55px;
  flex-direction: column;
  justify-content: center;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 90%;
}

.search-input {
  width: 100%;
  padding: 10px 40px;
  height: 60px;
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #efefef;
  color: black;
  padding-right: 90px;
}

.search-input::placeholder {
  font-family: "Chunk Five", sans-serif;
  color: black;
  position: relative;
}

.search-button {
  font-family: "Chunk Five", sans-serif;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: black;
  color: #ffde59;
  padding: 10px 30px;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  height: 50px;
  width: 150px;
}

.search-hint {
  font-family: "Canva Sans", sans-serif;
  font-size: 14px;
  color: #777;
  margin-top: 5px;
  text-align: left;
  margin-left: 40px;
}

/* TODO */
.test-section {
  width: 100%;
  height: 100px;
  display: flex;
  margin-top: 55px;
  flex-direction: column;
  justify-content: center;
}

.test-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 90%;
}

.test-button {
  font-family: "Chunk Five", sans-serif;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: black;
  color: #ffde59;
  padding: 10px 30px;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  height: 50px;
  width: 300px;
}
/* Modal Styles */

::v-deep .el-dialog__header {
  border-radius: 25px 25px 0 0;
  letter-spacing: 2px;
}

::v-deep .el-dialog__footer {
  border-radius: 0 0 25px 25px;
}

::v-deep .el-dialog {
  border-radius: 25px;
  height: 70vh;
  overflow: auto;
}

.modal-content {
  display: flex;
}

.product-image {
  margin-left: 30px;
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 40%;
  object-fit: contain;
}

.product-image img {
  width: 100%;
  height: 100%;
}

.product-info {
  flex: 2;
  margin-left: 40px;
}

.product-info h2 {
  font-size: 24px;
  color: #302b2b;
  margin: 0;
}

.product-info h3 {
  font-size: 12px;
  color: #302b2b;
}

.price {
  font-size: 12px;
  color: #302b2b;
}

.variant-section {
  margin-top: 20px;
  color: #302b2b;
}

/* Variant Sections */
.variant-section {
  margin-top: 20px;
}

.variant-options {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

/* Color Variant Styling as Circle */
.variant-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ddd;
  display: inline-block;
}

/* Variant Circle with Black Border for Selection */
.variant-circle.selected {
  border: 2px solid black; /* Outer black border */
  box-shadow: 0 0 0 6px white; /* White gap between color and outer border */
}

/* Other Variant Styling as Square */
.variant-square {
  display: inline-block;
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

/* Selected Square Variant */
.variant-square.selected {
  background-color: black;
  color: #ffde59;
}

/* Actions */
.actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.add-to-cart-button {
  background-color: #ffde59;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.more-links-button {
  background-color: #333;
  color: #ffde59;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}
</style>
