<template>
    <div class="details">
      <h3>Shipments</h3>
      <p>Your Shipments details will be shown here.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Shipments'
  };
  </script>
  