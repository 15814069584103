import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/global.css';
import router from './router'; // Import the router
import store from './store'; // Import Vuex store


Vue.use(ElementUI);
Vue.config.productionTip = false

// Mount the Vue instance and inject the router
new Vue({
  router, // Add the router here
  store, // Register Vuex store here
  render: h => h(App),
}).$mount('#app');
