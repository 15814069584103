<template>
    <div class="details">
      <h3>AccountBook</h3>
      <p>Your AccountBook details will be shown here.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AccountBook'
  };
  </script>
  