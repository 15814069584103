<template>
  <section class="home-page">
    <div class="home-text">
      <h1>
        One Cart, <br />
        Limitless Brands
      </h1>
      <p>
        Enjoy the convenience of a single transaction for top-tier athletic
        wear, cutting-edge tech, stylish footwear, premium lifestyle brands and
        MORE! Simplify your shopping experience and discover the joy of
        effortless purchases—all in one place!
      </p>
      <router-link to="/brands">
        <button class="shop-now-button">SHOP NOW</button>
      </router-link>
      
    </div>
    <div class="home-image">
      <img src="@/assets/panda.jpeg" alt="Person in streetwear" />
    </div>
  </section>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style scoped>
.home-page {
  display: flex;
  justify-content: space-between;
  padding: 77px;
  background-color: yellow;
}

.home-text {
  max-width: 50%;
}

.home-text p {
  font-size: 1.25rem;
  margin: 20px 0;
}

.home-text button {
  padding: 10px 20px;
  background-color: black;
  color: yellow;
  border: none;
  cursor: pointer;
}

.home-image img {
  border-radius: 50%;
  width: 300px;
  height: 300px;
}

.shop-now-button {
  background-color: black;
  color: yellow;
  padding: 15px 30px; /* Adjust padding for size and shape */
  border: none;
  border-radius: 50px; /* This makes the edges round */
  font-size: 18px; /* Adjust font size */
  cursor: pointer;
  text-transform: uppercase; /* Makes text all uppercase */
  transition: background-color 0.3s ease; /* Smooth hover transition */
}
</style>
