import Vue from 'vue';
import Router from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import BrandList from '@/components/BrandList.vue';
import OrderSuccess from '@/components/OrderSuccess.vue';
import Login from '@/components/Login.vue'; 
import AccountDetails from '@/components/AccountDetails.vue';
import Purchases from '@/components/AccountDetailsSub/Purchases.vue';
import OrderDetails from '@/components/AccountDetailsSub/OrderDetails.vue';
import AccountInfo from '@/components/AccountDetailsSub/AccountInfo.vue';
import Shipments from '@/components/AccountDetailsSub/Shipments.vue';
import AddressBook from '@/components/AccountDetailsSub/AddressBook.vue';
import Warehouse from '@/components/AccountDetailsSub/Warehouse.vue';
import Cart from '@/components/Cart.vue';

// Tell Vue to use the Router
Vue.use(Router);

export default new Router({
  mode: 'history', // Use 'history' mode to avoid hash in URLs
  routes: [
    {
      path: '/',
      redirect: '/home' // Redirect from root to /home
    },
    {
      path: '/home',
      name: 'Home',
      component: HomePage
    },
    {
      path: '/brands',
      name: 'Brands',
      component: BrandList
    },
    {
      path: '/order-success',
      name: 'OrderSuccess',
      component: OrderSuccess
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },{
      path: '/cart',
      name: 'Cart',
      component: Cart
    },
    {
      path: '/account-details',
      name: 'AccountDetails',
      component: AccountDetails,
      children: [
        {
          path: 'account-info',
          name: 'AccountInfo',
          component: AccountInfo,
        },
        {
          path: 'purchases',
          name: 'Purchases',
          component: Purchases
        },
        {
          path: 'purchases/:orderId', // Dynamic route for purchase details
          name: 'OrderDetails',
          component: OrderDetails
        },
        {
          path: 'shipments',
          name: 'Shipments',
          component: Shipments
        },
        {
          path: 'warehouse',
          name: 'Warehouse',
          component: Warehouse
        },{
          path: 'address-book',
          name: 'AddressBook',
          component: AddressBook
        }
      ]
    }
  ]
});
