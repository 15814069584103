<template>
    <div class="details">
      <h3>Account Details</h3>
      <div class="detail-item">
        <p class="label"><strong>Account No.</strong></p>
        <p class="value">99887766</p>
      </div>
      <div class="detail-item">
        <p class="label"><strong>Account Name.</strong></p>
        <p class="value">Meimei Han</p>
      </div>
      <div class="detail-item">
        <p class="label"><strong>Phone.</strong></p>
        <p class="value">+1 954-874-2156</p>
      </div>
      <div class="detail-item">
        <p class="label"><strong>E-mail.</strong></p>
        <p class="value">MeimeiHan@gmail.com</p>
      </div>
      <div class="detail-item">
        <p class="label"><strong>Primary Address.</strong></p>
        <p class="value">1 Unionville Rd, New York USA 32156</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AccountInfo'
  };
  </script>
  
  <style scoped>
  .details {
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: 'Canva Sans', sans-serif;
    color: #333;
  }
  
  h3 {
    font-family: 'Chunk Five', serif;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .detail-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .label {
    width: 200px; /* Label width */
    font-weight: bold;
  }
  
  .value {
    flex: 1;
    text-align: left;
  }
  </style>
  