<template>
    <div class="order-details">
      <h3>Order. {{ orderNo }}</h3>
  
      <div class="order-item" v-for="item in orderItems" :key="item.id">
        <div class="product-image">
          <img :src="item.image" alt="Product Image" />
        </div>
        <div class="product-info">
          <p><strong>{{ item.brandName }}, {{ item.productName }}</strong></p>
          <p>Variant A, Variant B, Variant C</p>
        </div>
        <div class="product-price">
          <p><strong>${{ item.price }}</strong></p>
        </div>
        <div class="product-quantity">
          <p>x{{ item.quantity }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OrderDetails',
    data() {
      return {
        orderNo: '123456789', // Example order number
        orderItems: [
          {
            id: 1,
            brandName: 'Brand Name',
            productName: 'Product Name',
            price: 30,
            quantity: 1,
            image: '@/assets/product-image.png'
          },
          {
            id: 2,
            brandName: 'Brand Name',
            productName: 'Product Name',
            price: 30,
            quantity: 1,
            image: '@/assets/product-image.png'
          },
          {
            id: 3,
            brandName: 'Brand Name',
            productName: 'Product Name',
            price: 30,
            quantity: 1,
            image: '@/assets/product-image.png'
          }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .order-details {
    padding: 20px;
  }
  
  h3 {
    font-family: 'Chunk Five', serif;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .order-item {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .product-image img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-right: 20px;
  }
  
  .product-info {
    flex: 1;
  }
  
  .product-price,
  .product-quantity {
    font-family: 'Canva Sans', sans-serif;
    font-weight: bold;
    text-align: right;
  }
  
  .product-price {
    margin-right: 30px;
  }
  
  .product-quantity {
    margin-right: 10px;
  }
  </style>
  