<template>
  <nav class="navbar">
    <router-link to="/home">
      <div class="navbar-brand">Borderless.</div>
    </router-link>

    <ul class="navbar-menu">
      <li>Services</li>
      <li>Contact</li>
    </ul>

    <div class="navbar-right">
      <span>1 CAD ≈ 0.74 USD + 5% Fees</span>
      <router-link to="/cart">
        <img src="@/assets/cart3.png" class="cart" alt="Shopping Cart" style="height: 20px;margin-left: 10px;" />
      </router-link>
      

      <router-link v-if="isLoggedIn" to="/account-details/account-info">
        <p class="login-button">My Account</p>
      </router-link>

      <router-link v-else to="/login">
        <p class="login-button">Login / Sign Up</p>
      </router-link>

      <p v-if="isLoggedIn" class="login-button" v-on:click="logout">Sign-Out</p>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Navbar',
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    ...mapActions(['logout'])
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: black;
  color: #ffde59;
}

.navbar-brand {
  font-family: 'Chunk Five', serif;
  font-size: 36px;
  border: none;
  margin-left: 50px;
  color: #ffde59;
}

.navbar-menu {
  margin-top: 30px;
  display: flex;
  list-style-type: none;
  font-family: 'Open Sauce', serif;
  font-size: 13px;
  font-weight: 200;
  margin-left: 80px;
}

.navbar-menu li {
  margin: 0 15px;
  color: #ffde59;
}

.navbar-right {
  position: absolute;
  right: 30px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.router-link-active {
  text-decoration: none;
  color: #ffde59;
}

a {
  text-decoration: none;
  color: #ffde59;
}

.login-button {
  margin-left: 20px;
}

.login-button:hover {
  transform: scale(1.15); /* Hover effect */
  font-weight: bold;
}

.cart:hover {
  transform: scale(1.3); /* Hover effect */
}
</style>
