<template>
    <section class="order-success-page">
      <!-- Message Section -->
      <div class="message-container">
        <h1>Your order has been placed!</h1>
        <p>You will receive a confirmation e-mail once your order is ready to ship.</p>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'OrderSuccess'
  };
  </script>
  
  <style scoped>
  .order-success-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    padding: 20px;
  }
  
  .message-container {
    text-align: center;
  }
  
  .message-container h1 {
    font-family: 'Chunk Five', serif;
    font-size: 36px;
    font-weight: bold;
  }
  
  .message-container p {
    font-family: 'Canva Sans', sans-serif;
    font-size: 18px;
    margin-top: 20px;
    color: #666;
  }
  </style>
  