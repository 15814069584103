<template>
  <section class="cart-page">
    <!-- Cart Items List -->
    <div
      v-for="item in cartData.items"
      :key="item.product_id"
      class="cart-item"
    >
      <div class="item-image">
        <img :src="item.image" alt="Product Image" />
      </div>
      <div class="item-details">
        <h4>{{ cartData.order_id }}</h4>
        <!-- Display Order ID as per API response -->
        <h3>{{ item.product_name }}</h3>
        <p class="variants">
          <span v-for="(variant, index) in item.variants" :key="index">
            {{ variant.variant_type }}: {{ variant.variant_value }}
            <span v-if="index < item.variants.length - 1">, </span>
          </span>
        </p>
        <div class="item-price-quantity">
          <p class="price">$30</p>
          <!-- Assuming a static price, replace with dynamic if available -->
          <p class="quantity">x {{ item.quantity }}</p>
        </div>
      </div>
    </div>

    <!-- Order Summary Section -->
    <div class="order-summary">
      <div class="summary-row">
        <span>Subtotal</span>
        <span>${{ subtotal }}</span>
      </div>
      <div class="summary-row">
        <span>Tax</span>
        <span>${{ tax }}</span>
      </div>
      <div class="summary-row">
        <span>Fee</span>
        <span>${{ fee }}</span>
      </div>
      <div class="summary-row total">
        <span>TOTAL</span>
        <span>CAD ${{ total }}</span>
      </div>
      <p class="note">*最终商品价格和运费将会在发货前进行调整。</p>
      <button class="proceed-button" @click="proceedToCheckout">Proceed</button>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "Cart",
  data() {
    return {
      cartData: {
        cart_id: null,
        order_id: "",
        items: [],
      },
      subtotal: 0,
      tax: 10, // Assuming a static tax for now
      fee: 10, // Assuming a static fee for now
    };
  },
  computed: {
    total() {
      return this.subtotal + this.tax + this.fee;
    },
  },
  methods: {
    async fetchCart() {
      // Check login status
      if (!this.$store.getters.isLoggedIn) {
        alert("You need to login to search for products.");
        this.$router.replace("/login");
        return;
      }

      const loading = this.$loading({
          lock: true,
          text: '正在加载您的购物车',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      try {
        const response = await axios.get(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/cart",
          {
            headers: { Authorization: `Bearer ${this.$store.state.token}` },
          }
        );
        this.cartData = response.data;
        this.calculateSubtotal();
        
      } catch (error) {
        console.error("Failed to fetch cart items:", error);
      }
      loading.close();
    },
    calculateSubtotal() {
      // Assuming a static price of $30 for each product, adjust this if prices are dynamic
      const pricePerItem = 30;
      this.subtotal = this.cartData.items.reduce(
        (acc, item) => acc + pricePerItem * item.quantity,
        0
      );
    },
    proceedToCheckout() {
      // Logic to proceed to checkout
      console.log("proceedToCheckout");
      this.$router.push("/order-success");
    },
  },
  mounted() {
    this.fetchCart();
  },
};
</script>

<style scoped>
.cart-page {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #f9f9f9;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.item-image {
  width: 80px;
  height: 80px;
  background-color: #eaeaea;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-details {
  flex: 1;
}

.item-details h4 {
  font-size: 12px;
  color: #333;
  margin: 0;
  font-weight: bold;
}

.item-details h3 {
  font-size: 16px;
  color: #111;
  margin: 5px 0;
  font-weight: bold;
}

.variants {
  font-size: 14px;
  color: #777;
  margin: 5px 0;
}

.item-price-quantity {
  display: flex;
  align-items: center;
}

.price {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.quantity {
  font-size: 14px;
  margin-left: 20px;
  color: #555;
}

.order-summary {
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.total {
  font-weight: bold;
  font-size: 18px;
}

.note {
  font-size: 12px;
  color: #777;
  text-align: center;
  margin: 10px 0;
}

.proceed-button {
  background-color: black;
  color: #ffde59;
  padding: 15px;
  border-radius: 25px;
  border: none;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
}

</style>
