<template>
    <div class="details">
      <h3>WareHouse</h3>
      <p>Your WareHouse details will be shown here.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WareHouse'
  };
  </script>
  